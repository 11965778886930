import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppConfig from '../AppSettings';

const ImageSlider = ({ Products, onImageClick }) => {
    const settings = {
        dots: true, // Navigation dots
        infinite: true, // Loop through images
        speed: 400, // Smooth sliding transition
        slidesToShow: 1, // Show one image at a time
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Speed between auto scrolls
        arrows: true, // Enable navigation arrows
        pauseOnHover: true, // Pause autoplay when user hovers
        cssEase: "ease-in-out", // Smooth sliding animation
        responsive: [
            {
                breakpoint: 1920, // Large screens
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024, // Tablets
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600, // Mobile
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // Smaller mobile screens
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="image-slider-container">
            <Slider {...settings}>
                {Products.map((product, index) => (
                    <div key={index} className="slide">
                        <img
                            src={`${AppConfig.ImageUrl}/${product.ItemFileName}`}
                            alt={index}
                            className="slider-image"
                            onClick={() => onImageClick(product)} // Handle click action
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default ImageSlider;
