import React, { useEffect, useState } from 'react';
import Appbar from './AppBar';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation
} from "react-router-dom";
import NewArrival from './NewArrival';
import ProductPD from './Product_PD';
import ProductList from './ProductList';
import DynamicFormXSD from './DynamicFormXSD';
import { CartProvider } from '../context/CartContext';
import ViewCart from './ViewCart';
import Aboutus from './About-us';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../components/footer';
import { Helmet } from 'react-helmet';


const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            await navigator.serviceWorker.register('/service-worker.js');
            console.log('Service Worker registered successfully.');
        } catch (error) {
            console.log('Service Worker registration failed:', error);
        }
    }
};
function Home() {
    const location = useLocation(); // Get the current location

    useEffect(() => {
        //registerServiceWorker(); // Register service worker on component mount
    })
    // Function to generate dynamic meta tags based on the current route
    const getMetaTags = () => {
        const baseUrl = "https://www.energize-lb.com"; // Replace with your actual base URL
        const currentUrl = `${baseUrl}${location.pathname}`;

        switch (location.pathname) {
            case "/":
                return {
                    title: "Energize - Home",
                    description: "Welcome to Energize, your one-stop shop for all energy products.",
                    keywords: "energy drinks, supplements, nutrition, protein, gym, lebanon, shop",
                    image: `${baseUrl}/logo192.png`,
                    type: "website"
                };
            case "/about-us":
                return {
                    title: "About Us - Energize",
                    description: "Learn more about Energize, our mission, and our commitment to quality.",
                    keywords: "about Energize, mission, company values",
                    image: `${baseUrl}/logo192.png`,
                    type: "website"
                };
            case location.pathname.match(/^\/Product_PD\/.*$/)?.input:
                return {
                    title: "Product Details - Energize",
                    description: "Explore the details of our high-quality products including size, flavor, and more.",
                    keywords: "product details, flavors, sizes",
                    image: `${baseUrl}/logo192.png`,
                    type: "article"
                };
            case location.pathname.match(/^\/ProductList\/.*$/)?.input:
            case location.pathname.match(/^\/ProductListByBrand\/.*$/)?.input:
            case location.pathname.match(/^\/ProductListByCat\/.*$/)?.input:
                return {
                    title: "Product List - Energize",
                    description: "Discover our wide range of products. Browse by category or brand.",
                    keywords: "product list, categories, brands,Supplements,protein,gym,lebanon,shop",
                    image: `${baseUrl}/logo192.png`,
                    type: "website"
                };
            case "/cart":
                return {
                    title: "Your Cart - Energize",
                    description: "View the items in your shopping cart and proceed to checkout.",
                    keywords: "cart, checkout, shop",
                    image: `${baseUrl}/logo192.png`,
                    type: "website"
                };
            default:
                return {
                    title: "Energize - Home",
                    description: "Welcome to Energize, your one-stop shop for all energy products.",
                    keywords: "energy drinks, supplements, nutrition",
                    image: `${baseUrl}/logo192.png`,
                    type: "website"
                };
        }
    };

    // Get the current meta tags based on the route
    const metaTags = getMetaTags();

    return (
        <div>
            <Helmet>
                {/* Basic Meta Tags */}
                <title>{metaTags.title}</title>
                <meta name="description" content={metaTags.description} />
                <meta name="keywords" content={metaTags.keywords} />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Your Company Name" />
                <meta name="copyright" content="&copy; 2024 Your Company Name" />

                {/* Open Graph Meta Tags for Social Media */}
                <meta property="og:title" content={metaTags.title} />
                <meta property="og:description" content={metaTags.description} />
                <meta property="og:image" content={metaTags.image} />
                <meta property="og:type" content={metaTags.type} />
                <meta property="og:url" content={metaTags.url} />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={metaTags.title} />
                <meta name="twitter:description" content={metaTags.description} />
                <meta name="twitter:image" content={metaTags.image} />

                {/* Other Important Meta Tags */}
                <link rel="canonical" href={metaTags.url} />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <CartProvider>
                <Appbar />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<NewArrival />} />
                    <Route path="/Product_PD/:itemName/:size?/:flavor?" element={<ProductPD />} />
                    <Route path="/ProductList/:Products" element={<ProductList />} />
                    <Route path="/ProductListByBrand/:Products" element={<ProductList />} />
                    <Route path="/ProductListByCat/:Products" element={<ProductList />} />
                    <Route path="/cart" element={<ViewCart />} />
                    {/*<Route path="/DynamicFormXSD" element={<DynamicFormXSD />} />*/}
                    <Route path="/about-us" element={<Aboutus />} />
                </Routes>
                <Footer />
            </CartProvider>
        </div>
    );
}

export default Home;
